@import './src/scss/variables';
@import './src/scss/app';
@import 'ol/ol';

#map {
  width: 100%;
  height: 350px;

  .ol-viewport {
    border: 1px solid;
  }
}

.fa-location-dot::before {
	$font-size: $font-size5;
}

.widget__result-table td:first-of-type:first-letter {
	text-transform: uppercase;
}

.ol-attribution.ol-uncollapsible {
	visibility: hidden;
}

.widget__result-table_tbody {
	&:first-of-type {
		tr:nth-child(7) td:last-of-type:first-letter {
			text-transform: uppercase;
		}
	}

	tr:last-of-type {
    border-bottom: 1px solid;
    font-weight: 600;
	}

	&:last-of-type {
		tr {
	    &:last-of-type {
	      display: none;
	    }

	    &:nth-child(12) {
	      border-bottom: 0;
	    }
		}
	}
}

.widget__result {
	width: 100%;

	&-table {
    overflow: auto;
	}
}

@import 'dark-theme';