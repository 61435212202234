@import './src/scss/variables';

.dark-theme {
	#map {
		background: $dark-color6;
	}

	.fa-location-dot::before {
		color: $dark-color2;
	}
}